import React from "react"
import SimplePage from "../components/page/SimplePage"

import { MainBanner } from "../components/home/MainBanner"
import Offers from "../components/offers/Offers"
import { HowItWorks, HowItWorksTitle } from "../components/HowItWorks"
import { graphql } from "gatsby"

const OffersPage = ({ data }) => {
  return (
    <SimplePage title="So funktioniert's">
      <HowItWorksTitle showTitle={false} />
      <HowItWorks page="rules" />
    </SimplePage>
  )
}

export default OffersPage
